* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  background-color: #314052;
  color: "white";
}

// section {
//   padding-top: 2.5rem;
//   padding-bottom: 2.5rem;
// }

#root {
  background-image: radial-gradient(
    circle at 1px 1px,
    #495c72 1px,
    transparent 0
  );
  background-size: 40px 40px;
  background-position: left 20px top 20px;
}
